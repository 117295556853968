.sign-in-wrapper{
    height: 100%;
    width: 50%;
    display: flex;
    padding: 5%;
    .verify-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img{
            width: 10%;
            margin-bottom: 20px;
        }
        .blue-line{
            width: 100px;
            height: 1px;
            background-color: #1E2DBE;
        }
    }
    @media screen and (max-width:700px){
        width: 100%;
    }
    div {
        width: 100%;
        h1{
            margin-bottom: 10px;
        }
        span{
            display: flex;
            flex-direction: row;
            align-items: center;
            p{
                margin-right: 10px;
            }
            a{
                text-decoration: none;
                color: #1E2DBE;
            }
            
        }
        form{
            .forgot-holder{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                p {
                    margin-right: 20px;
                    color: rgba(0, 0, 0, 0.311);
                    cursor: pointer;
                }
            }
        }
    }
}