.mini-circles-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    
    h2{
        margin: 0;
    }
    .mini-circles{
        width: 50px;
        height: 50px;
        flex: none;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        .mini-circle{
            border-radius: 100%;
        }
    }
}
