.show-text-box{
    box-shadow: 1.53349px 3.06697px 3.83372px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    padding-left: 20px;
    padding-right: 50px;
    
   width: 100%;
   text-align: left;
    cursor: pointer;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
   
    &.mar{
       height: auto;
      cursor: initial;
      overflow: auto;
      h4{
        white-space: pre-wrap;
        cursor: initial;
        @media screen and (max-width:700px) {
            font-size:3vw;
        }
      }
    }
   height: 50px;
    h4{
        color: white;
        margin: 0;
        white-space: nowrap;
        @media screen and (max-width:700px) {
            font-size:2.4vw;
        }
       &.mar{
        margin-top: 5px;
       }
    }
    #text{
        display: block;
        opacity: 0 ;
        margin: 0 ;
        padding-top: 10px;
        @media screen and (max-width:700px) {
            font-size:2.6vw;
        } 
    }
    .showText-butt{
        background-color: white;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        transform: rotate(0deg);
       
        right: 10px;
        top: 10px;
        
    }
    svg {
        width: 15px;
        height: 15px;
        
    }
    #arrow{
        transform: rotate(180deg);
    }
}
.showText-butt{
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    transform: rotate(0deg);
   z-index: 4;
    right: 10px;
    top: 10px;
    pointer-events: none;
}
#arrow{
    transform: rotate(180deg);
}