.chapter-wrapper{
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 0px 5%;
    padding-left: 25%;
    padding-top: 150px;
    @media screen and (max-width:1024px) {
        padding: 0;
        padding-top: 175px;
        flex-direction: column;
    }
    .chapters-lessons{
        width: 80%;
        max-width: 900px;
        padding: 20px 50px;
        padding-bottom: 150px;
        @media screen and (max-width:1024px) {
            width: 100%;
            padding: 20px;
        }
        p{
            font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #141522;
                text-align: justify;
        }
    }
}