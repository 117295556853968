*{
    -webkit-tap-highlight-color: transparent;

}
.labor-vs-equipment{
    width: 70%;
    margin-top: 40px;
    display: block;
    @media screen and (max-width:700px) {
        width: 100%;
    }
}
.retaining-walls{
    width: 100%;
    display: block;
    margin-bottom: 30px;
}
.site-organization-img{
    width: 100%;
    margin-top: 40px;
}
.boq-ch3-img{
    width: 100%;
    margin-top: 40px;
    display: block;
    @media screen and (max-width:700px) {
        width: 100%;
    }
}
.boq-ch4-img{
    width: 90%;
    margin-top: 40px;
    display: block;
    @media screen and (max-width:700px) {
        width: 100%;
    }
}

h1,h2,h3,h4,p{
    color: #230050;
}
button {
    margin-top: 60px;
    
}
h2{
    margin-top: 40px;
}
.legal-cont{
    display: flex;
    align-items: center;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}