.profile-wrapper{
    display: flex;
    flex-direction: column;
    padding: 3% 5%;
    margin-top: 100px;
    align-items: center;
    
    .profile-banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
        h1{
            margin: 0;
        }
        p{
            text-align: center;
        }
    }
    .certificate-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        border-bottom: 0.25px solid #0000001e;
       width: 100%;
        .certificate-box{
            
            padding: 20px;
            width: 60%;
            
            
            height: 600px;
            background-color: white;
            border-left: 10px solid #1E2DBE;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            @media screen and (max-width:700px) {
                width: 100%;
            }
            .logo-cer{
                position: absolute;
                top: 20px;
                left: 20px;
                width: 20%;
            }
          .certificate-ban{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40%;
          }
          h1{
            text-transform: capitalize;
          }
        }
        .certificate-holder{
            background-color: #F5F5F7;
            width: 70%;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:700px){
                width: 100%;
            }
            p{
                width: 50%;
                text-align: center;
            }
        }
    }
    .profile-info-holder{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        form{
            width: 60%;
            @media screen and (max-width:700px){
                width: 100%;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            button{
                width: 30%;
                margin-top: 30px;
            }
            .cl-3-inputs{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                @media screen and (max-width:700px){
                    flex-direction: column;
                }
                .inputs-holder{
                    width: 30%;
                    @media screen and (max-width:700px){
                        width: 100%;
                    }
                }
            }
        }
    }
}