.sequencing-box{
    background-color: #AB3B77;
    padding: 3px 20px;
    margin-bottom: 10px;
    width: 80%;
    padding: 10px 10px;
    min-height: 50px;
    display: flex;
    position: relative;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    @media screen and (max-width:700px) {
        width: 100%;
    }
    
    h4{
        color: white !important;
        margin: 0;
    }
}