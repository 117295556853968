.eiip-in-lebanon-circles{
   margin-top: 30px;
   min-height: 150px;
    .eiip-in-lebanon-circle{
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      @media screen and (max-width:500px) {
        width: 100px;
        height: 100px;
      }
        
        h3{
            margin: 0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                
        }
        &:nth-child(1) {
                
                border: 1px solid #FFC000;
                color: #FFC000;
                background-color: #FAB428;
                bottom: 0;
                left: 0;
                z-index: 2;
                h3{
                    color: black;
                }
                :hover{
                    background-color: #FAB428;
                   
                }
            }
            
            
                            &:nth-child(3) {
                                border: 1px solid transparent;
                               
                                background-color: #FB636F;
                                bottom: 0;
                                left: 0;
                                z-index: 2;
                                h3{
                                    color: white;
                                }
                                :hover {
                                    border: 1px solid #FB636F;
                                    background-color: transparent;
                                        color: white;
                                    }
                            }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
}
.black-line{
    margin: 0 20px;
}