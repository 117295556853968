.redpoly-list{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    
    p {
        margin: 0;
        line-height: 20px !important;
        @media screen and (max-width:700px) {
            
            max-width: 100%;
        }
    }
    svg{
        width: 15px;
        height: 15px;
        margin-right: 10px;
        flex: none;
        margin-top: 3px;
    }
}