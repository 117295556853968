.quiz-template-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    .submit-assignment-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.25px solid rgba(0, 0, 0, 0.267);
        padding-bottom: 20px;
        h2{
            margin-right: 30px;
        }
    }
    .grade-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 0.25px solid rgba(0, 0, 0, 0.267);
        .grade-holder {
                border-left: 0.25px solid rgba(0, 0, 0, 0.267);
                padding-left: 30px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        .receive-grade-wrapper{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            
            h3{
                margin: 0;
                margin-top: 20px;
            }
            .grade-to-pass-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    h3{
                        margin-right: 30px;
                        font-family: 'Noto Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            color: #9C9CA4;
                            margin-top: 0px;
                    }
                    p{
                        font-family: 'Noto Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 150%;
                            color: #9C9CA4;
                    }
                }
        }
        
    }
}