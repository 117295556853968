.bread-crumb{
    position: fixed;
    left: 0;
    right: 0;
    top: 100px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    background-color: white;
    z-index: 5;
    @media screen and (max-width:1024px) {
        flex-direction: column;
        padding:0 20px;
        height: 75px;
    }
    .breadcrumb-links{
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width:1024px){
            width: 100%;
            padding: 20px 0px;
            overflow-x: scroll;
            overflow-y: hidden;
        }
        a {
            margin-right: 10px;
            text-decoration: none;
            cursor: pointer;
            color: #1e2dbe;
            @media screen and (max-width:1024px){
                flex: none;
            }
            &:hover{
               
                text-decoration: underline;
            }
            p{
                
                color: #1e2dbe;
                white-space: nowrap;
                @media screen and (max-width:1024px){
                    margin: 0;
                }
            }
        }
        p{
            white-space: nowrap;
        }
    }
    .breadcrumb-nav{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        @media screen and (max-width:1024px){
            width: 100%;
            justify-content: space-between;
        }
        a{
            margin:0 10px;
            color: #230050;
            text-decoration: none;
            font-weight: 600;
            transition: opacity 300ms ease-in;
            @media screen and (max-width:1024px){
                margin: 0;
            }
            &:hover{
                opacity: 0.5;
            }
        }
    }
}