.circle-show-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    .close-cir{
        width: 70px;
        height: 70px;
        border: 1px solid white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50%;
        cursor: pointer;
        margin-top: 10%;
        svg{margin: 0;}
        @media screen and (max-width:1024px) {
            margin: 0;
            margin-top: 10px;
        }
    }
    div{
        display: flex;
        flex-direction: row;
        position: relative;
        width: fit-content;
        h2{
            margin-top: 0;
            margin-right: 10px;
            margin-bottom: 5px;
        }
    }
    .inCircle{
        position: fixed;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        opacity: 1;
       cursor: pointer;
       
      top: 0;
        left: 0;
      
        z-index: 999;
        display: none;
        opacity: 0;
        align-items: center;
        justify-content: flex-start;
        color: white;
       transform-origin: 50% 50%;
       
       p{
        opacity: 0;
      color: white;
      margin: 0;
      margin-left: 20px;
     margin-right: 20px;
       
       }
       .close-circle{
        height: 0px;
        width: 0px;
        border-radius: 100%;
        border: 1px solid white;
        position: absolute;
        bottom: 10%;
        left: 50%;
        opacity: 0;
        z-index: 1;
        pointer-events: fill;
       }
       overflow: hidden;
        
        
       
    }
    .circleShow{
        width: 15px;
        height: 15px;
        border-radius: 100%;
        opacity: 0.2;
        padding: 2px;
        position: relative;
        cursor: pointer;
        transition: all 400ms ease-out;
       z-index: 996;
        animation: pulse-animation infinite 2000ms ease;
        
        .full{
            width: 100vw;
            border-radius: 0;
            animation: none;
            z-index: 1;
            overflow: hidden;
            height: 100vh;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            background-color: transparent;
        }
    }
    .text-wrap{
        position: fixed;
        transform: translateY(100px);
        left: 60%;
        right: 0;
        top: 0;
        height: 100%;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        opacity: 0;
        width: 50%;
        z-index: 10000;
        @media screen and (max-width:1024px) {
            left: 0%;
            text-align: center;
            padding: 0px;
            align-items: center;
            width: 100%;
        }
        h4{
            color: white;
            max-width: 70%;
            @media screen and (max-width:1024px) {
                max-width: 60%;
            }
            @media screen and (max-width:600px) {
                max-width: 90%;
            }
        }
    }
    .pulse{
        animation: pulse-animation 2s infinite;
    }
    @keyframes pulse-animation {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        50%{
            opacity: 0.8;
        }
        100% {
            transform: scale(2);
            opacity: 0;
        }
      }
}

