.video-wrapper{
    width: 100%;
    position: relative;
    div{
        width: 100% !important;
        height: 400px !important;
    }
    .video-end{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #1F054D;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 50px;
        h4{
            color: white;
        }
        h2{width: 70%; color: white;}
        button{
            background-color: white;
            color: #1F054D;
            
        }
    }
}