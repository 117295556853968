.home-wrapper{
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    margin-top: 100px;
    @media screen and (max-width:700px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .home-info{
        width: 50%;
        @media screen and (max-width:700px){
            width: 100%;
        }
        h1{
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 900;
                font-size: 30px;
                line-height: 125%;
                color: #1F054D;
                margin-top: 5px;
                @media screen and (max-width:700px){
                    font-size: 20px;
                }
        }
        h2,a{
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 27px;
                line-height: 113%;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: #FA3C4B;
                margin-bottom: 5px;
                text-decoration: none;
                @media screen and (max-width:700px){
                    font-size: 20px;
                }
        }
        a{
            text-transform: capitalize;
            letter-spacing: 0px;
            display: flex;
            align-items: center;
        }
    }
    img {
        width: 40%;
        @media screen and (max-width:700px){
            width: 70%;
            margin-top: 50px;
        }
    }
}