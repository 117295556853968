.chapter-one-description-wrapper{

    h3 {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            margin-bottom: 0;
        }
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px 0;
        p{
            margin-bottom: 0;
            margin-top: 0;
        }
        svg{
            margin-right: 10px;
        }
    }
    button{
        margin-top: 20px;
    }
}