.big-rectangle{
    width: 100%;
    height: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
    .big-rectangle-number{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;   
    }
}