.contractor-training-wrapper{
.sub-sections{
.red-box {
        background-color: #FA3C4B;
        color: white;
        padding: 10px 10px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 10px;
        @media screen and (max-width:700px){
            width: 100%;
        }
        h1 {
            margin: 0;
            color: white;
            @media screen and (max-width:700px){
                font-size: 20px;
                line-height: 30px;
                margin: 0;
            }
        }
    }
    p{margin: 0;}
    .poly-holder{
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-right: 10px;
            width: 20px;
        }
    }
}
   
}