.matrix-risk-table{
    border-spacing: 15px;
    th{
        background-color:#1E2DBE ;
        color: white;
        padding: 10px 20px;
        border-radius: 8px;
        
    }
    td{
       
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        background-color: #EBF5FD;
    }
}