.chapters-nav {
    width: 20%;
    padding: 10px;
    height: auto;
    position: fixed;
    overflow: scroll;
    bottom: 0;
    top: 0;
    left: 5%;
    background-color: white;
    top: 150px;
    @media screen and (max-width:1024px) {
        width: 100%;
        display: none;
    }
    h3{
        cursor: pointer;
    }
    h4{
        margin: 0;
        margin-top: 10px;
        margin-bottom: 0px;
        cursor: pointer;
    }
    .navlinks-holder{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-bottom: 30px;
        a{
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #141522;
                padding: 10px 20px;
                border-left: 5px solid transparent;
                    ;
                &:hover{
                    background-color:  #F5F5F7;
                    border-left: 5px solid #1E2DBE;;
                }
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    flex: none;
                }
            img{
                width: 20px;
                margin-right: 15px;
            }
        }
    }
}
.chapters-nav-phone {
    width: 20%;
    padding: 20px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    bottom: 0;
    top: 0;
    left: 5%;
    background-color: white;
    .menu-burger{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        div{
            width: 100%;
            height: 2px;
            background-color:#141522;
            border-radius: 4px;
            margin: 5px 0px;
        }
    }
    h3{
        margin: 0;
    }
 
    display: none;
    
    @media screen and (max-width:1024px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        left: 0;
    }
    h3{
        cursor: pointer;
    }
    h4{
        margin: 0;
        margin-top: 10px;
        margin-bottom: 0px;
        cursor: pointer;
    }
    .navlinks-holder{
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding-bottom: 30px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        height: 100vh;
        z-index: 9999999;
        max-width: 100vw;
        transform: translateX(-100%);
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
        h3{
            text-align: right;
            position: absolute;
            right: 10px;
            top: 10px;
        }
        a{
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #141522;
                padding: 10px 20px;
                border-left: 5px solid transparent;
                    ;
                &:hover{
                    background-color:  #F5F5F7;
                    border-left: 5px solid #1E2DBE;;
                }
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    flex: none;
                }
            img{
                width: 20px;
                margin-right: 15px;
            }
        }
    }
}