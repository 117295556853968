.strategy-eiip-wrapper{
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        svg{
            width: 30px;
            margin-right: 10px;
        }
        p{font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 20px !important;
            line-height: 30px !important;
        color: #000000;}
    }
}