.inputs-holder{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label{
        text-transform: capitalize;
        margin: 10px 0px;
        }
    input {
        border: 1px solid rgba(0, 0, 0, 0.115);
        border-radius: 10px;
        height: 50px;
        padding-left: 10px;
       
        &::placeholder{
            color: rgba(14, 12, 12, 0.294);
        }
        &:focus{
            outline: none;
            border: 1px solid #1E2DBE;
        }
    }
}