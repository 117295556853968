.equipments-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        object-fit: contain;
        height: 100px;
        width: 37%;
    }
    .options-box{
        width: 80%;
        border: 0.7px solid #3264c830;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        padding: 15px;
        position: relative;
       cursor: pointer;
        svg{
            position: absolute;
            right: 10px;
            top:50%;
            transform: translateY(-50%);
        }
        &.true{
            background: #EBF5FD;
box-shadow: 1.53349px 3.06697px 3.83372px rgba(0, 0, 0, 0.25);
        }
        h4{
            margin: 0;
        }
    }
}

.protective-slider{
    .swiper-button-next{
        top: 15%;
        width: 10%;
        
        &::after{
            content: "next";
            font-size: 25px;
            color: #230050;
        }
    }
    .swiper-button-prev{
        top: 15%;
        width: 10%;
        
        &::after{
            content: "prev";
            font-size: 25px;
            color: #230050;
        }
    }
}