.eiip-in-lebanon-wrapper{
    padding-bottom: 100px;
    .red-box{
        background-color: rgb(251, 99, 111);
        color: white;
        padding: 20px;
        width: 100%;
        margin-bottom: 30px;
        @media screen and (max-width:700px){
            width: 100%;
        }
        
        h3{
        font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: white;
            line-height: 40px;
            @media screen and (max-width:700px){
                font-size: 20px;
                line-height: 30px;
                margin: 0;
            }
        }
       
    }
    .list-holder{
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-right: 30px;
        }
    }
   
}