.auth-banner-wrapper{
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-image: url('../../assets/auth-banner.jpg');
    background-position: 0% 0%;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    @media screen and (max-width:700px){
        width: 100%;
        height: 600px;
    }
    h1,h3,p{
        color: white;
    }
    p{
        width: 70%;
    }
}