.work-table {
    border-spacing: 20px;
    th{
        background-color:#1E2DBE ;
        color: white;
        padding: 10px 20px;
        border-radius: 0px;
        
    }
    td{
      
        border-radius: 0px;
        
        text-align: center;
    }
    h4{
        margin: 5px ;
    }
    .tr-1{
        background-color: #EBF5FD;
        
    }
    .tr-2{
        background-color: #BEDCFA;
        @media screen and (max-width:1000px) {
            min-width: 400px;
        }
    }
    .tr-3{
        background-color: #82AFDC;
       
    }
    .tr-4{
        background-color: #5A87CD ;
        
    }
    .db-t{
        display: flex;
        flex-direction: column;
        
        td{
           
            margin-bottom: 10px;
        }
    }
}