.navbar{
    width: 100vw;
    height: 100px;
    display: flex;
    position: fixed;
    top: 0;
    background-color: white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    z-index: 99;
    border-bottom: 1px solid rgba(0, 0, 0, 0.275);
    .nav-left,.nav-right{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .nav-left{
        a{
            margin-right: 20px;
        }
        form {
            display: none;
            border: 1px solid #1E2DBE;
            border-radius: 8px;
            @media screen and (max-width:1024px) {display: none;}
            input{
                height: 40px;
                border-radius: 8px;
                border: none;
                padding-left: 10px;
            }
            button{border-radius: 8px;height: 40px;margin-top: 0;}
        }
    }
    .nav-right{
        position: relative;
        cursor: pointer;
        svg{
            margin: 0 10px;
        }

        .submenu{
            display: flex;
            flex-direction: column;
            background-color: #F5F5F7;
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            width: 100%;
            border-radius: 5px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            transition: all 400ms ease-in-out;
            z-index: 99;
            a{
                text-decoration: none;
                color: #1F054D;
                border-bottom: 1px solid #9C9CA4;
                padding: 10px;
                transition: opacity 300ms ease-in;
                &:hover{
                    opacity: 0.4;
                }
                &:nth-child(4){
                    border-bottom: none;
                }
            }

        }
    }
}
@media screen and (max-width:1024px) {
    
}