.international-labour-wrapper{
    .circles-holder{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    .circle {
        border-radius: 100%;
        height: 100px;
        width: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 4px;
        justify-content: center;
        margin: 10px; 
        box-shadow: 22px 39px 18px rgba(0, 0, 0, 0.01), 13px 22px 15px rgba(0, 0, 0, 0.05), 6px 10px 11px rgba(0, 0, 0, 0.09), 1px 2px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        transition: all 300ms ease-in-out;
        cursor: pointer;
            
            
            
          
                      h1 {
                          margin: 0;
                          height: 100%;
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 100%;
                         
                          color: white;
                          pointer-events: none;
                      }
                      h3{
                        margin: 0;
                        color:  white;
                        text-align: center;
                      }
        
                  }
    }
      
    .answer-box{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        h1{margin-right: 20px;color: #1E2DBE;}
    }
}