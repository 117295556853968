.task-rates-poly {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.task-rates-red-border{
    border: 1px solid red;
    padding: 10px 20px;
    margin-bottom: 30px;
    p{
        padding: 10px 20px;
        &:nth-child(1){
            border-bottom: 1px solid red;
           }
    }
    
}
.red-title{
    color: red;
}