.blue-text-box{
    width: 80%;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    background-color: #3264C8;
    height: auto;
    min-height: 50px;
    cursor: pointer;
    padding: 10px 10px;
    margin-bottom: 15px;
    margin-top: 10px;
   
    @media screen and (max-width:700px) {
        width: 100%;
    }
    h4{
        margin: 0;
        color: #230050 !important;
    }
    p{
       
    }
    svg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        height: 70%;
    }
}