.contractors-wrappers{
    table {
        border-spacing: 20px;
        th{
            background-color:#1E2DBE ;
            color: white;
            padding: 10px 20px;
            
            
        }
        td{
            background-color: #EBF5FD;
           
            padding: 20px;
            text-align: center;
        }
    }
}

.tables-holder{
    width: 100%;
    @media screen and (max-width:1000px) {
        overflow-x: scroll;
        max-width: 100vw;
    }
}