.scoreBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    width: 100%;
    h2{
        margin-top: 10px;
    }
    button{
        width: fit-content;
        margin-top: 15px;
        margin-bottom: 20px;
    }
}
.correct{
    background-color: #d1f3c134;
    color: #70B450;
    width: 100%;
    padding: 10px 15px;
    margin-top: 20px;
    h4{
        margin: 0;
    }
}
.incorrect {
    background-color: #FED8DB;
    color: #FA3C4B;
    width: 100%;
    padding: 10px 15px;
    margin-top: 20px;
    h4{
        margin: 0;
     }
     p{
        margin: 0;
    }
}
.passed{
    background-color: #d1f3c134;
    span {
        color:#70b450;
    }
}
.failed {
    background-color:#FED8DB ;
    span {
        color: #FA3C4B;
    }
}
.attempt {
    .input-att{
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: flex-start;
    }
  input{
    flex: none;
  }
    label{
        margin-left: 5px;
        text-align: left;
        
    }
    button{
        margin-top: 30px;
    }
}
.try-again{
    background-color: #FA3C4B;
    &:hover{
        background-color: #FA3C4B;
        color: white;
        border: 1px solid transparent;
    }
}