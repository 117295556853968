.chapters-wrapper{
    min-height: calc(100vh - 100px);
    display: flex;
    padding: 0 5%;
   
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 100px;
    @media screen and (max-width:1024px) {
        height: auto;
    }
    button{
        margin-top: 0px;
    }
    .chapters-holder{
        width: 100%;
        .chaptersBlock {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(128, 128, 128, 0.256);
            @media screen and (max-width:1024px) {
                flex-direction: column;
                justify-content: flex-start;
                padding: 30px;
            }
        }
        h1{
            color: #1F054D ;
            font-style: normal;
                font-weight: 700;
                font-size: 27px;
                line-height: 150%;
        }
        div{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0px;
            @media screen and (max-width:1024px) {
                flex-direction: column;
            }
            cursor: pointer;
            .chapterNumber{
                background-color: #6E889C;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                color: white;
                border-radius: 100%;
                margin-right: 20px;
                font-weight: 600;
                font-size: 12px;
                @media screen and (max-width:1024px) {
                    margin-right: 0;
                }
            }
            svg {
                margin-right: 20px;
            }
            h2{
                margin-top: 0;
                margin-bottom: 0;
                text-transform: capitalize;
                color: #6E889C;
                font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 150%;
                    @media screen and (max-width:1024px) {
                        text-align: center;
                    }
            }
        }
    }
    .chapters-images{
        height: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        width: 40%;
        border-radius: 25px;
        text-align: center;
        background: #D9D9D9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: white;
        filter: drop-shadow(25px 25px 20px rgba(0, 0, 0, 0.07));
    }
}

.progress {
    background-color: #EDF0F2;
    color: #3264C8;;
}
.completed{
    background-color:  #70B450;
    color: white;
}