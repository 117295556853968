.examples-slider{
    width: 100%;
    position: relative;
    .swiper{
        width: 100%;
        .swiper-slide{
            img{
                height: 300px;
                object-fit: cover;
            }
        }
        .left-arr,.right-arr{
            height: 40px;
            width: 40px;
            background-color: #1E2DBE;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            cursor: pointer;
            
            z-index: 2;
            border-radius: 100%;
            svg{
                fill: white;
            }
            &.swiper-button-disabled{
                background-color: #EDF0F2;
                svg{
                   fill: #6E889C;
                }
            }
        }
        .left-arr{
            left: 10px;
        }
        .right-arr{
            right: 10px;
        }
    }
}