.work-plan-box{
    background-color: #FB636F;
    padding: 3px 20px;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    h4{
        color: white !important;
        margin: 0;
    }
    
}
.svg-poly{
    width: 15px !important;
    height: 15px !important;
}