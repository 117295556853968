.sentence-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    .sentence-words-holder{
      
       
        margin: 0;
        cursor: pointer;
        p{
            margin: 0;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: 700;
            transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform;
        }
    }
    
}
.sentence-words-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
    width: 100%;
    .sentence-words-holder{
        width: fit-content;
       
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;

        p{
            margin: 0;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: 700;
            transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
            will-change: transform;
        }
    }
}

.scaleBox{

    transform: scale(0);
    transition: transform .6s cubic-bezier(.16,1,.3,1);
    will-change: transform;
}
.scaleBoxIn{

    transform: scale(1);
    transition: transform .6s cubic-bezier(.16,1,.3,1);
    will-change: transform;
    transition-delay: 0.2s;
}

.hideBox{
    width: 0px;
    transition-duration: 0.6s;
    p{
        padding: 0;
    }

}
.showBox{
    width: fit-content;
    transition-duration: 0s;
    will-change: width;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}