.choice-holder{
    padding-top: 150px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width:700px) {
        flex-direction: column;
    }
    .lf-choice{
        .replaced{
            transform: translateX(100%);
            margin-top: 20px;
            @media screen and (max-width:700px){
                transform: translateX(0%) rotate(90deg);
            }
        }
    }
    .rf-choice{
        padding-top: 200px;
        padding-left: 50px;
        @media screen and (max-width:700px){
            padding-left: 0;
            padding-top: 150px;
        }
    }
    .choice-box{
        display: flex;
        flex-direction: column;
        border: 11px solid #BEDCFA;
        width: 320px;
        position: relative;
        padding: 20px;
        img{
           position: absolute;
           top: -120px;
           left: 20px;
           width: 85%;
        }
        span{
            color: red;
        }
        h3{
            color: #230050;
            font-style: normal;
font-weight: 700;
font-size: 22px;
        }
    }
}
