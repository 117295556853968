.dashboard-wrapper{
    background-color:  rgba(237, 240, 242, 0.69);
    min-height: 100vh;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    padding: 50px 20px;
    .table-holder{
        width: 80%;
        min-width: 800px;
        border-radius: 10px;
        @media screen and (max-width:800px) {
            overflow-x: scroll;
            width: 100%;
            min-width: auto;
        }
    }
    @media screen and (max-width:800px) {
        align-items: flex-start;
        overflow: hidden;
    }
    h1{
    font-weight: 600;
    font-size: 27px;
    color: #1E2DBE;
    margin-bottom: 10px;
    }
    .dashboard-top{
        width: 80%;
        min-width: 800px;
        margin-bottom: 20px;
        position: relative;
        @media screen and (max-width:800px) {
            width: 100%;
            min-width: auto;
        }
       
        .export-button{
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 20px;
            border-radius: 10px;
        }
    }
    .search-bar{
        width: 100%;
        background-color: white;
        padding: 5px;
        border-top-left-radius: 10px;
        td{
            border-top-left-radius: 10px;
        }
        input {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 10px;
            
            width: 100%;
            background-color: #EBF5FD;;
            border: none;
            padding: 15px 20px;
            padding-left: 50px;
            border-radius: 10px;
            &::placeholder{
                color: #1E2DBE;
            }
        }
    }
    .filters-holder{
        display: flex;
        flex-direction: row;
        button{
            border-radius: 0;
            &:hover{
                border: 1px solid transparent;
            }
           margin-top: 0;
         
           background-color: transparent;
           color: #1E2DBE;
           font-size: 16px;
           &.active{
            border-radius: 0;
            border: 0px solid transparent;
            border-bottom: 2px solid #1E2DBE;
           }
        }
    }
    table{
        background-color: white;
        border-radius: 10px;
        width: 100%;
        border-collapse: collapse;
        min-width: 800px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        .empty-th{
        
        }
        .pagination{
            width: 100%;
           
            background-color: #EBF5FD;
            border-collapse: collapse;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            color: #1E2DBE;
            font-size: 14px;
            font-weight: 600;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            button{
                background-color: transparent;
                margin-top: 0;
                &:hover{
                    border: 1px solid transparent;
                }
                &:disabled{
                    opacity: 0.5;
                }
            }
            select{
                background-color: transparent;
                border: none;
                color: #1E2DBE;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                margin-right: 30px;
                margin-left: 10px;
            }
        }
        .long{
           
            position:absolute;
            bottom: 0;
            left: 0;
            align-items: flex-start;
            right: 0;
            transform: translateY(100%);
            z-index: 2;
            background-color: white;
            height: 0;
            overflow: hidden;
            padding: 0 5.5%;
            h4{
                color: #1E2DBE;
                span{
                    font-weight: 400;
                }
            }
        }
        .search-icon{
            position: absolute;
            top: 50%;
            left: 6%;
            transform: translateY(-50%);
        }
        .icon-td{
           
            .icon-holder{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                cursor: pointer;
                svg{
                    transform: rotate(-90deg);
                    transition: all 200ms ease;
                }
            }
        }
        thead{
            background-color: #EBF5FD;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
           th{
            color:#1E2DBE;
            cursor: pointer;
            font-size:15px;
            border-top: 1px solid #BEDCFA;
            border-bottom: 1px solid #BEDCFA;
            padding: 15px 5px;
            font-weight: 800;
            text-align: left;
           }
        }
        tbody{
            tr{
              position: relative;
                td{
                    padding: 5px;
                    border-bottom: 1px solid #BEDCFA;
                    &.user-name{
                        font-size: 16px;
                        color: #151F85;
                        h2{
                            margin: 0;
                            font-size: 16px;
                            color: #151F85;
                        }
                        h3{
                            margin: 0;
                            color: #1E2DBE;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    &.user-date{
                        font-size: 14px;
                        color: #151F85;
                    }
                    .certified-box{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .certificate-date{
                            color: #1E2DBE;
                            font-size: 14px;
                            font-weight: 400;
                            margin-top: 5px;
                        }
                        .certified{
                            background-color:  #CDFFCD;
                            border-radius: 50px;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 5px 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .certified-dot{
                                width: 7px;
                                height:7px;
                                background-color: #385A28;
                                border-radius: 100%;
                                display: flex;
                                margin-right: 5px;
                            }
                        }
                    }
                    .inprogress-box{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .certificate-date{
                            color: #1E2DBE;
                            font-size: 14px;
                            font-weight: 400;
                            margin-top: 5px;
                        }
                        .certified{
                            background-color:  #FFE0E0;
                            border-radius: 50px;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 5px 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            color: #D30000;
                            .certified-dot{
                                width: 7px;
                                height:7px;
                                background-color: #D30000;
                                border-radius: 100%;
                                display: flex;
                                margin-right: 5px;
                            }
                        }
                    }
                   
                }
            }
        }
    }
}