.auth-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    @media screen and (max-width:700px){
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
    }
}