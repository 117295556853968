.exercice-form{
    label{
        margin-bottom: 30px;
    }
    
    
    button{
        margin-bottom: 20px;
        margin-top: 20px;
    }
}