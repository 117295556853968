button {
    background-color: #1E2DBE;
    border: 1px solid transparent;
    color: white;
   
    height: 35px;
    padding: 0 15px;
    cursor: pointer;
    transition: all 300ms ease-in;
    font-family: 'Noto Sans' , sans serif;
    font-weight: 600;
    text-transform: capitalize;
    
    &:hover{
        background-color: transparent;
        border: 1px solid #1E2DBE;
        color: #1E2DBE;
    }
    &:disabled{
        background-color: #6E889C;
        &:hover{
            background-color: #6E889C;
            color: white;
            border: 1px solid transparent;
        }
    }
    a{
        color: white;
        text-decoration: none;
        &:hover{
            color: #1E2DBE;
        }
    }
}