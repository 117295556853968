.intensive-wrapper{
    

    .circles-infra {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width:700px) {
            flex-direction: column;
        }
       .rightIcon{
            @media screen and (max-width:700px){
                transform: rotate(90deg);
            }
            margin: 10px 10px;
        }
        .circle-infra{
            width: 100px;
            height: 100px;
            background-color: #1E2DBE;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            margin-right: 20px;
            h1{
                color: white;
            }
            @media screen and (max-width:700px) {
                margin-right: 0;
            }
        }
       
        .all-circles{
            position: relative;
            height: 280px;
            width: 290px;
            margin-left: 20px;
            margin-top: 30px;
            margin-bottom: 30px;
            @media screen and (max-width:700px){
                margin: 0;
            }
            .big-circles{
                width: 150px;
                height: 150px;
                position: absolute;
                text-align: center;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                text-align: center;
                padding: 15px;
                p{
                    font-weight: 500;
                    text-align: center;
                }
                
                &:nth-child(1) {
                       background-color: #3264C8;
                        color: white;
                        top: 0;
                        left: 0;
                        z-index: 4;
                    }
                 &:nth-child(2) {
                       background-color: #FAB428;
                        color: white;
                        top: 0;
                        right: 0;
                        z-index: 3;
                    }
                 &:nth-child(3) {
                       background-color: #FB636F;
                        color: white;
                        bottom: 0;
                        left: 0;
                        z-index:2;
                    }
                &:nth-child(4) {
                      background-color: #AB3B77;
                                    color: white;
                                    bottom: 0;
                                    right: 0;
                                    z-index: 2;
                                }
                                .eye-holder{
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    .eye {
                                    
                                            width: 60%;
                                            cursor: pointer;
                                            @media screen and (max-width:700px){
                                                width: 70%;
                                            }
                                        }
                                        p{margin: 0;line-height: 20px;}
                                }
               
                p{
                    color: white;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
                         
        }
    }
        ul {
            padding-left: 10px;
            @media screen and (max-width:700px){
                margin-left: 10px;
            }
        }
}