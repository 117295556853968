.socio-box-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    @media screen and (max-width:700px) {
        flex-direction: column;
    }
    
    .socio-square{
        background-color: #05BDBD;
        width: calc(100%/3 - 50px);
        @media screen and (max-width:700px){
            width: 100%;
        }
        height: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px;
        margin: 5px;
        margin-right: 40px;
        margin-bottom: 50px;
        &.extra{
            width:  calc(100%/1.5 - 50px);
            @media screen and (max-width:700px){
              width: 100%;
          }
            
          }
        cursor: pointer;
        .socio-number{
            position: absolute;
            top: 0;
            background-color: white;
            color:#05BDBD ;
            border: 1px solid #05BDBD;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
        }

        .click-icon{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        h3{
            color: #230050;
            text-align: center;
        }
        p{
            text-align: center;
            color: #230050;
        }
    }
}