.square-box{
    width: calc(100%/3 - 20px);
    @media screen and (max-width:700px) {
        width: calc(100%/2 - 20px); 
    }
    height: 200px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    justify-content: center;
    box-shadow: 0px 102px 41px rgba(0, 0, 0, 0.01), 0px 57px 34px rgba(0, 0, 0, 0.05), 0px 25px 25px rgba(0, 0, 0, 0.09), 0px 6px 14px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}