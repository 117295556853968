.two-inputs-holder{
    display: flex;
    @media screen and (max-width:700px){
        flex-direction: column;
    }
    span{
        width: 50%;
        padding-right: 10px;
        @media screen and (max-width:700px){
            width: 100%;
        }
    }
}