.workers-res-holder{
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 400px;
    .blue-half-circle{
        height: 100px;
        background-color: #3264C8;
        
        
        width: 100px * 2;
        border-top-left-radius: 100px * 2;
    border-top-right-radius: 100px * 2;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    left: 20%;
    top: 50px;
    h1{
        display: none;
        opacity: 0;
        font-size: 120px;
        color: white;
        text-align: center;
    }
    h2{
        display: none;
        opacity: 0;
        color: white;
        margin-left: 20px;
        margin-right:20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    h3{color:white;text-align: center;}
    }
    .red-half-circle{
        height: 100px;
        background-color: #FB636F;
        cursor: pointer;
        
        width: 100px * 2;
        border-bottom-left-radius: 100px * 2;
    border-bottom-right-radius: 100px * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    left: 40%;
    top: 100px;
    h1{
        display: none;
        opacity: 0;
        font-size: 120px;
        color: white;
        text-align: center;
    }
    h2{
        display: none;
        opacity: 0;
        color: white;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right:20px;
    }
    h3{color:white;text-align: center;}
    }
}